import React, { useState } from "react";
import { Box, Typography, Link, Button, Modal } from "@mui/material";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import { Link as RouterLink } from "gatsby";

import Play from "../../../images/media/play.inline.svg";
import CloseIcon from "../../../images/x.inline.svg";

import slugify from "react-slugify";

const modalStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  p: "20px",
};

const videoStyle = {
  backgroundColor: "rgba(0,0,0,1)",
  width: "100%",
  maxWidth: 1000,
  maxheight: "90%",
  position: "relative",
  boxShadow: "0 0 30px 0 rgba(0,0,0,2)",
};

export interface VideoProps {
  title: string
  desc: string
  descPreformatted: boolean
  mediaUrl: string
  type: string
  cover: string
  date: string
}

const Video = ({
  title, desc, descPreformatted, mediaUrl, type, cover, date
}: VideoProps) => {
  const [open, setOpen] = useState(false);
  const [videoID, setVideoID] = useState({
    video: "",
    platform: "",
  });

  const handleOpen = (video: string, platform: string) => {
    setVideoID({
      video: video,
      platform: platform,
    });
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setVideoID({
      video: "",
      platform: "",
    });
  };

  const openVideo = (url: string) => {
    let VimeoRegExp = /https:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;
    let YoutubeRegExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;

    if (YoutubeRegExp.test(url)) {
      let video_id = url.split("v=")[1];
      let ampersandPosition = video_id.indexOf("&");
      if (ampersandPosition != -1) {
        video_id = video_id.substring(0, ampersandPosition);
      }
      handleOpen(video_id, "youtube");
    } else if (VimeoRegExp.test(url)) {
      let match = url.match(VimeoRegExp);

      if (match) {
        handleOpen(match[2], "vimeo");
      }
    }
  };

  return (
    <Box
      component="div"
    >
      <Box component="div" sx={{ maxWidth: 1082, mx: "auto" }}>



        <Box component="div" sx={{ mb: 7 }}>
          <Link
            component={OutboundLink}
            href={mediaUrl}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => {
              if (type == "Video") {
                e.preventDefault();
                openVideo(mediaUrl);
              }
            }}
            sx={{
              border: "1px solid #4B608D",
              aspectRatio: "16/9",
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              "&:after": {
                content: '""',
                position: "absolute",
                background: "rgba(12, 31, 71, 0.8)",
                opacity: "0.95",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
              },
            }}
          >
            <Typography
              sx={{
                position: "absolute",
                left: "0",
                top: "18px",
                pointerEvents: "none",
                color: "#1CE7C2",
                borderLeft: "4px solid #1CE7C2",
                pl: 0.9,
                lineHeight: 1,
                zIndex: 2,
                // background: "#051537",
                py: "2px",
                pr: "3px",
                px: "4px",
              }}
            >
              {type}
            </Typography>

            <Play style={{ position: "relative", zIndex: 2 }} />
            <Box
              component="img"
              src={cover}
              alt={title}
              sx={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </Link>
          <Box
            component="div"
            sx={{
              mt: 2,
              pr: { md: 4 },
              "& h5": {
                fontSize: 21,
                lineHeight: 1.2,
              },
            }}
          >
            <Typography sx={{ fontSize: 12, color: "#1CE7C2" }}>
              {date}
            </Typography>
            <Link
              component={RouterLink}
              to={`/media/${slugify(title)}`}
            >
              <Typography variant="h5" sx={{ mt: 0.8, mb: 1 }}>
                {title}
              </Typography>
            </Link>
            <Typography sx={{ fontSize: 12 }} component={descPreformatted ? "pre" : "span"} >{desc}</Typography>
          </Box>
        </Box>



      </Box>

      <Modal open={open} onClose={handleClose} sx={modalStyle}>
        <Box component="div" sx={videoStyle}>
          <Button
            onClick={handleClose}
            sx={{
              width: 30,
              height: 30,
              border: "1px solid #fff",
              borderRadius: "50%",
              position: "absolute",
              right: -15,
              top: -15,
              display: "block",
              zIndex: 2,
              backgroundColor: "#fff",
              svg: {
                fill: "#000",
                transition: "250ms cubic-bezier(0.4, 0, 0.2, 1)",
              },
              "&:hover": {
                backgroundColor: "#fff",
                svg: {
                  transform: "scale(1.25)",
                  fill: "red",
                },
              },
            }}
          >
            <CloseIcon />
          </Button>
          <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
            {videoID.platform === "vimeo" ? (
              <iframe
                src={`https://player.vimeo.com/video/${videoID.video}?h=773c1a597b&autoplay=1&loop=1&color=ffffff&title=0&byline=0&portrait=0`}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                frameBorder={0}
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
              />
            ) : (
              <iframe
                src={`https://www.youtube.com/embed/${videoID.video}?autoplay=1`}
                title=""
                frameBorder={0}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              />
            )}
          </div>
        </Box>
      </Modal>
    </Box>
  );
};

export default Video;
